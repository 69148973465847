<template>
  <v-container id="home" fluid tag="section" class="pa-10">
    <v-progress-linear color="primary" indeterminate rounded height="10" />
  </v-container>
</template>

<script>
  import UserRole from '@/services/order/UserRole';

  export default {
    name: 'Home',
    mounted () {
      if (this.$store.state.user !== undefined) {
        if (this.$store.state.location === undefined) {
          switch (this.$store.state.user.role) {
            case UserRole.admin:
              this.$router.push({ name: 'overview' });
              break;
            case UserRole.agent:
              this.$router.push({ name: 'customer-service/orders' });
              break;
            case UserRole.admin_accountant:
              this.$router.push({ name: 'overview' });
              break;
            case UserRole.canvasser:
            case UserRole.commercial:
              this.$router.push({ name: 'prospectingZones' });
              break;
            case UserRole.buyer:
              this.$router.push({ name: 'purchase/merchandises' });
              break;
            case UserRole.manager:
            case UserRole.accountant:
            case UserRole.picker:
            case UserRole.driver:
            default:
              this.$router.push({ name: 'login' });
          }
        } else {
          switch (this.$store.state.user.role) {
            case UserRole.admin:
            case UserRole.manager:
              this.$router.push({ name: 'dashboard' });
              break;
            case UserRole.picker:
              this.$router.push({ name: 'preparation' });
              break;
            case UserRole.driver:
              this.$router.push({ name: 'mydelivery' });
              break;
            case UserRole.distributor:
              this.$router.push({ name: 'distribution' });
              break;
            case UserRole.admin_accountant:
            case UserRole.accountant:
              this.$router.push({ name: 'dashboard' });
              break;
            case UserRole.agent:
            default:
              this.$router.push({ name: 'search' });
              break;
          }
        }
      } else {
        this.$router.push({ name: 'base' });
      }
    }
  };
</script>
